import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { Link } from "../../../../components/Link"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Klassiker: Trenchcoat"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={12}>
          <Typography paragraph={true}>
            Der Trenchcoat ist ein Klassiker der Modewelt und wird, sobald es Frühling oder Herbst
            wird, gern getragen. In der Stadt, in einem Restaurant beim Café trinken, oder auf dem
            Weg ins Büro. Der Trenchcoat ist jedes Jahr im Trend und daher auch ein Basic. Klassisch
            für den Trenchcoat sind Details wie die Schulterklappen, sowie der Gürtel und die
            Ärmelriegel. Dabei sind zum einen der Trenchcoat mit integriertem Gürtel von Finn Flare
            und zum anderen der Trenchcoat von RISA absolute Trenchcoat-Klassiker. Der zweireihige
            Mantel von Comma aus weicher Wolle- und Nylon-Mischung hingegen ist ein Must-Have für
            jede Frau, die sich nicht zwischen einem Mantel oder einem Trenchcoat entscheiden kann.
            Eine schwarze{" "}
            <Link href="/umhaengetaschen/" underline="always">
              Umhängetasche
            </Link>{" "}
            passt perfekt zu jedem Trenchcoat.
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/Btx2QCUiToI/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608292501&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Aniston CASUAL Trenchcoat mit Gürtel",
                    src: "https://i.otto.de/i/otto/30032942?w=1750&h=2500",
                    title: "Aniston CASUAL Trenchcoat mit Gürtel zum Regulieren",
                  },
                ],
                name: "Aniston CASUAL Trenchcoat",
                price: 99.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608082971&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer TOMMY HILFIGER Trenchcoat »Bogart Trench«",
                    src: "https://i.otto.de/i/otto/28785642?w=1750&h=2500",
                    title:
                      "TOMMY HILFIGER Trenchcoat »Bogart Trench« Mit Details im Glencheckdesign",
                  },
                ],
                name: "TOMMY HILFIGER Trenchcoat",
                price: 145.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609212793&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer RISA Trenchcoat Trenchcoats",
                    src: "https://i.otto.de/i/otto/34513553?w=1524&h=2200",
                    title: "RISA Trenchcoat Trenchcoats",
                  },
                ],
                name: "RISA Trenchcoat",
                price: 239.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608795565&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Finn Flare Trenchcoat mit integriertem Gürtel",
                    src:
                      "https://i.otto.de/i/otto/30b4a402-bb76-5d97-b012-8bb9d3c8eb2f?w=1600&h=2400",
                    title: "Finn Flare Trenchcoat mit integriertem Gürtel",
                  },
                ],
                name: "Finn Flare Trenchcoat",
                price: 160.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608972185&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer Y.A.S Tartangürtel Trenchcoat",
                    src: "https://i.otto.de/i/otto/33483973?w=900&h=1200",
                    title: "Y.A.S Trenchcoat",
                  },
                ],
                name: "Y.A.S Trenchcoat",
                price: 94.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609101285&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer HALLHUBER Trenchcoat mit Gehschlitz",
                    src: "https://i.otto.de/i/otto/34198652?w=1524&h=2200",
                    title: "HALLHUBER Trenchcoat mit Gehschlitz",
                  },
                ],
                name: "HALLHUBER Trenchcoat",
                price: 199.99,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24608972909&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild einer SELECTED FEMME Recycling-Polyester Trenchcoat",
                    src: "https://i.otto.de/i/otto/33550643?w=900&h=1200",
                    title: "SELECTED FEMME Recycling-Polyester Trenchcoat",
                  },
                ],
                name: "SELECTED FEMME Trenchcoat",
                price: 199.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609237353&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild eines Comma Mantel",
                    src: "https://i.otto.de/i/otto/34499743?w=1060&h=1500",
                    title: "Comma Mantel",
                  },
                ],
                name: "Comma Mantel",
                price: 329.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24721698709&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild eines Y.A.S Zweireihigen Mantel",
                    src: "https://i.otto.de/i/otto/34948096?w=900&h=1200",
                    title: "Y.A.S Zweireihiger Mantel",
                  },
                ],
                name: "Y.A.S Mantel",
                price: 139.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609212781&a=667803&m=14336",
                images: [
                  {
                    alt: "Ein Bild eines Usha Kurzmantels",
                    src: "https://i.otto.de/i/otto/34514796?w=1524&h=2200",
                    title: "Usha Kurzmantel Trenchcoats",
                  },
                ],
                name: "Usha Kurzmantel",
                price: 299.95,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
